import { jwtDecode } from 'jwt-decode';
import { IDAM_AUTH } from '../../env';
import { settingsStore } from '../../store/settingsStore';
import { ACCOUNT_ID_ITEM, TOKEN_ITEM } from '../constants';
import { getAccountId, getToken } from './localStorage';

export const areCredentialsValid = (): boolean => {
  const token = getToken();
  const accountId = getAccountId();

  if (token && accountId) {
    const userData: { exp: number } = jwtDecode(token);
    const currentTime = Math.floor(Date.now() / 1000);

    return userData.exp > currentTime;
  }

  return false;
};

const setCredentials = (hash: string): void => {
  const params = new URLSearchParams(hash.substring(1)); // Remove the '#' from the URL
  const accountId = params.get('state');
  const token = params.get('access_token');

  if (accountId && token) {
    settingsStore.setCredentials({ accountId, token });

    window.localStorage.setItem(ACCOUNT_ID_ITEM, accountId);
    window.localStorage.setItem(TOKEN_ITEM, token);
  } else {
    throw new Error();
  }
};

const getIdamUrl = (accountId: string): string => {
  const nonce = crypto.randomUUID();
  const baseUrl = IDAM_AUTH.BASE_URL;

  const queryParams = {
    client_id: IDAM_AUTH.CLIENT_ID,
    nonce,
    realm_id: IDAM_AUTH.REALM_ID,
    redirect_uri: window.location.origin,
    response_type: IDAM_AUTH.RESPONSE_TYPE,
    scope: IDAM_AUTH.SCOPE,
    state: `${accountId}`,
    user_type: IDAM_AUTH.USER_TYPE,
  };

  return `${baseUrl}?${new URLSearchParams(queryParams)}`;
};

// Authenticates the logged in user in an in-app browser flow based on credentials passed from the app
export const authenticateUser = (): Promise<void> =>
  new Promise<void>((resolve, reject) => {
    const authUrl = new URL(window.location.href);
    const searchParams = authUrl.searchParams;

    // check for authentication errors in the URL
    if (searchParams.has('error')) {
      reject(
        'Authentication error in url ' + searchParams.get('error_description')
      );
      return;
    }

    // check if local storage credentials are valid
    if (areCredentialsValid()) {
      settingsStore.setCredentials({
        accountId: getAccountId()!,
        token: getToken()!,
      });
      resolve();
      return;
    }

    // handle search parameters in the URL before IDAM redirect
    if (searchParams.toString()) {
      const accountId = [...searchParams.entries()]
        .find(([key]) => key.toLowerCase() === ACCOUNT_ID_ITEM.toLowerCase())
        ?.at(1);

      if (accountId) {
        // pass through accountId – it will be returned by IDAM in hash
        window.location.replace(getIdamUrl(accountId));
        return;
      }
      reject('Missing authentication credentials');
    }

    // handle url hash after IDAM redirect
    if (authUrl.hash) {
      try {
        setCredentials(authUrl.hash);
        resolve();
      } catch {
        reject('Missing access token or state in auth url');
      }
    }
    reject('Authentication failed');
  });
