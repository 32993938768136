<script lang="ts">
  export let color: string;
</script>

<svg
  width="89"
  height="82"
  viewBox="0 0 89 82"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <circle
    cx="6.77279"
    cy="14.5948"
    r="4.09091"
    stroke="#002D72"
    stroke-width="3.63636"
  />
  <circle
    cx="6.77279"
    cy="14.5948"
    r="4.09091"
    stroke="white"
    stroke-opacity="0.8"
    stroke-width="3.63636"
  />
  <circle
    cx="80.4092"
    cy="8.2294"
    r="5.90909"
    stroke="#002D72"
    stroke-width="3.63636"
  />
  <circle
    cx="80.4092"
    cy="8.2294"
    r="5.90909"
    stroke="white"
    stroke-opacity="0.8"
    stroke-width="3.63636"
  />
  <path
    d="M2.22729 80.5H73.1364"
    stroke="#002D72"
    stroke-width="2.72727"
    stroke-linecap="round"
  />
  <path
    d="M2.22729 80.5H73.1364"
    stroke="white"
    stroke-opacity="0.8"
    stroke-width="2.72727"
    stroke-linecap="round"
  />
  <path
    d="M80.4091 80.5H87.2272"
    stroke="#002D72"
    stroke-width="2.72727"
    stroke-linecap="round"
  />
  <path
    d="M80.4091 80.5H87.2272"
    stroke="white"
    stroke-opacity="0.8"
    stroke-width="2.72727"
    stroke-linecap="round"
  />
  <g clip-path="url(#clip0_12869_14800)">
    <g opacity="0.2">
      <path
        d="M76.9148 36.3807L72.0142 31.5866V24.6619L65.7287 21.9986L63.0653 15.7131H56.1406L51.3466 10.8125L44.9545 13.4759L38.5625 10.8125L35.0469 14.3281L40.6932 16.6719L47.0852 14.0085L51.8793 18.9091H58.804L61.4673 25.1946L67.7528 27.858V34.7827L72.6534 39.5767L69.9901 45.9687L72.6534 52.3608L67.7528 57.1548V62.5881L72.0142 60.8835V53.9588L76.9148 49.1648L74.2514 42.7727L76.9148 36.3807Z"
        fill="white"
      />
    </g>
    <path
      d="M31.4357 46.2996L41.2384 54.4685C41.6535 54.8145 42.277 54.7241 42.5768 54.2744L58.4733 30.4297"
      stroke="#002D72"
      stroke-width="2.72727"
      stroke-linecap="round"
    />
    <circle
      cx="44.9545"
      cy="42.7727"
      r="32.2727"
      fill="white"
      stroke="#002D72"
      stroke-width="2.72727"
    />
    <path
      d="M20.5465 42.9321C19.2483 26.9848 31.6722 15.2715 38.0465 11.4083C29.0716 13.203 11.557 22.1384 13.2978 43.5222C15.0385 64.906 31.0986 71.7163 38.911 72.4485C33.3304 69.2544 21.8447 58.8793 20.5465 42.9321Z"
      fill="#F2F4F8"
    />
    <circle
      cx="44.9545"
      cy="42.7727"
      r="32.2727"
      stroke={color}
      stroke-width="2.72727"
    />
    <path
      d="M29.9545 57.7734L59.9545 27.7734"
      stroke={color}
      stroke-width="2.72727"
      stroke-linecap="round"
    />
    <path
      d="M59.9545 57.7734L29.9545 27.7734"
      stroke={color}
      stroke-width="2.72727"
      stroke-linecap="round"
    />
  </g>
  <defs>
    <clipPath id="clip0_12869_14800">
      <rect
        width="68.1818"
        height="68.1818"
        fill="white"
        transform="translate(10.8636 8.68359)"
      />
    </clipPath>
  </defs>
</svg>
