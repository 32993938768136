<script lang="ts">
  import { onMount } from 'svelte';
  import { t } from 'svelte-i18n';
  import type { Pagination } from '../../types/globals.types';
  import type { Invoice, MCreditSummary } from '../../types/mpay.types';
  import { MPAY_API_BASE_URI } from '../../env';
  import { dialogStore } from '../../store/dialogStore';
  import { invoicesStore } from '../../store/invoicesStore';
  import {
    fetchCreditSettleDetails,
    mCreditStore,
  } from '../../store/mCreditStore';
  import { settingsStore } from '../../store/settingsStore';
  import { topBar } from '../../store/TopBarStore';
  import { ERR_429, MPayApi } from '../constants';
  import { Context } from '../utils/analytics/keys';
  import { calculateDateRange, formatDate } from '../utils/date';
  import { sendRequest } from '../utils/fetch';
  import {
    getMergedInvoices,
    getSortedInvoicesByMonthYear,
  } from '../utils/invoices';
  import {
    EventsMapKeys,
    passBackendTracking,
    trackEvents,
  } from '../utils/tracking';
  import DateRangeFilter from './DateRangeFilter.svelte';
  import EmptyScreen from './EmptyScreen.svelte';
  import InvoicesList from './InvoicesList.svelte';
  import InvoicesPlaceholder from './InvoicesPlaceholder.svelte';
  import LinkButton from './LinkButton.svelte';
  import MyInvoicesFilter from './MyInvoicesFilter.svelte';
  import PaymentMethodFilter from './PaymentMethodFilter.svelte';

  let isDataLoading = false;
  let invoicesError: null | unknown = null;

  onMount(() => {
    // TODO: a more sophisticated and thorough check if to load new invoices or not
    if (Object.keys($invoicesStore.invoices).length > 0) {
      return;
    }
    fetchInvoices();

    if ($settingsStore.accountDetails.mcreditAccess) {
      fetchCreditSettleDetails();
    }
  });

  $: topBar.setContext(Context.Invoices);

  const fetchMoreInvoices = () => {
    if ($invoicesStore.pagination.totalPages <= $invoicesStore.currentPage) {
      return;
    }

    trackEvents(EventsMapKeys.LOAD_MORE_INVOICES, {
      category: $topBar.context,
      requestedPage: $invoicesStore.currentPage + 1,
    });
    invoicesStore.setCurrentPage($invoicesStore.currentPage + 1);
    fetchInvoices(true);

    setTimeout(() => {
      window.scrollBy({
        behavior: 'smooth',
        left: 0,
        top: window.innerHeight - 280,
      });
    }, 100);
  };

  function constructUrlWithParams() {
    const baseUrl = `${MPAY_API_BASE_URI}/${MPayApi.INVOICES}/${$settingsStore.credentials.accountId}`;
    const { endDate, startDate } = calculateDateRange(
      $invoicesStore.filters.dateRange
    );

    const params = new URLSearchParams({
      from: formatDate(startDate),
      limit: '100',
      onlyMyInvoices: `${$invoicesStore.filters.myInvoices}`,
      page: `${$invoicesStore.currentPage}`,
      to: formatDate(endDate),
    });

    if ($invoicesStore.filters.paymentMethod.length > 0) {
      $invoicesStore.filters.paymentMethod.forEach((code) => {
        params.append('paymentCode[]', code);
      });
    }

    const queryParams = params.toString();

    return `${baseUrl}?${queryParams}`;
  }

  const mergedInvoiceData = (invoices: Invoice[]) => {
    const newData = getSortedInvoicesByMonthYear(invoices);
    const storedData = $invoicesStore.invoices;

    return getMergedInvoices(storedData, newData);
  };

  function resetAndFetchInvoices() {
    invoicesStore.resetInvoices();
    fetchInvoices();
  }

  async function fetchInvoices(fetchNextPage = false) {
    isDataLoading = true;
    invoicesError = null;

    try {
      const response = await sendRequest<{
        _pagination: Pagination;
        invoices: Invoice[];
        paymentSummary: MCreditSummary;
      }>(constructUrlWithParams());

      invoicesStore.setInvoices({
        invoices: fetchNextPage
          ? mergedInvoiceData(response.invoices)
          : getSortedInvoicesByMonthYear(response.invoices),
        pagination: response._pagination,
      });

      if ($settingsStore.accountDetails.mcreditAccess) {
        mCreditStore.setMCreditSummary(response.paymentSummary);
      }

      if (!fetchNextPage) {
        passBackendTracking('totalMRIRequestCount', {
          value: response.paymentSummary.totalMRIRequestCount,
        });
      }
      passBackendTracking('responseInvoiceCount', {
        tab: Context.Invoices,
        value: response.invoices.length,
      });
    } catch (err) {
      invoicesError = err;
      if (fetchNextPage) {
        invoicesStore.setCurrentPage($invoicesStore.currentPage - 1);
      }

      if (
        invoicesError!.toString() !== ERR_429 ||
        !invoicesError!.toString().includes(ERR_429)
      ) {
        dialogStore.setRetryAction(fetchInvoices);
      }
    } finally {
      isDataLoading = false;
    }
  }
</script>

<section class="pt-6" data-cy="invoices">
  <div class="mx-4 mb-4 flex gap-2">
    <MyInvoicesFilter onSelect={resetAndFetchInvoices} />
    <DateRangeFilter onSelect={resetAndFetchInvoices} />
    <PaymentMethodFilter onSelect={resetAndFetchInvoices} />
  </div>

  {#if !isDataLoading && !invoicesError && Object.keys($invoicesStore.invoices).length === 0}
    <EmptyScreen label={$t('invoices.no_invoices')} />
  {:else}
    {#each Object.keys($invoicesStore.invoices) as invoicesMonth, index}
      <InvoicesList
        id="invoices-list"
        timePeriod={invoicesMonth}
        {index}
        invoices={$invoicesStore.invoices}
      />
    {/each}

    {#if $invoicesStore.pagination.totalPages > $invoicesStore.currentPage}
      <div class="flex justify-center">
        <LinkButton
          id="load-more-invoices"
          isDisabled={isDataLoading}
          onClick={fetchMoreInvoices}
          label={$t('general.cta.load_more')}
        />
      </div>
    {/if}
  {/if}

  {#if isDataLoading}
    <InvoicesPlaceholder />
  {/if}
</section>
