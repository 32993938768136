import './app.css';
import { locale } from 'svelte-i18n';
import App from './App.svelte';
import { colors } from './assets/styles/cssColors';
import { getLocale } from './i18n';
import './i18n';
import { showLoader } from './lib/JSBridge/loaderController';
import { registerGlobalNavigation } from './lib/JSBridge/navigationController';
import { authenticateUser } from './lib/utils/auth';
import {
  initFeatureFlags,
  PostHogFeatureFlagsError,
} from './lib/utils/featureFlags';
import { getStrippedUrl } from './lib/utils/helpers';
import { setPosthogUniqueId } from './lib/utils/uniqueIdentifier';
import { PostHogPluginMissingError } from './plugins/posthog';
import { initializeSentry } from './plugins/sentry';
import { fetchAccountDetails } from './store/settingsStore';

function createApp(props: Record<string, unknown>) {
  new App({
    props,
    target: document.getElementById('app')!,
  });
}

function getErrorProps(error: unknown) {
  const hasPostHogError = error instanceof PostHogPluginMissingError;
  const hasFeatureFlagsError = error instanceof PostHogFeatureFlagsError;
  const hasAuthError = !(hasPostHogError || hasFeatureFlagsError);

  return {
    hasAuthError,
  };
}

const init = async () => {
  let initialProps = {
    hasAuthError: false,
  };

  // hideLoader will be called in App.svelte
  showLoader();
  registerGlobalNavigation();

  try {
    await authenticateUser();

    const authUrl = new URL(window.location.href);
    window.history.replaceState(null, '', getStrippedUrl(authUrl.href));

    await fetchAccountDetails();
    await setPosthogUniqueId();
    await initFeatureFlags();

    if (import.meta.env.MODE !== 'test') initializeSentry();

    // Set the background color after successful initialization
    document.body.style.background = colors.semantic.appBackground;
  } catch (error) {
    console.error('Error during initialization:', error);

    initialProps = {
      ...initialProps,
      ...getErrorProps(error),
    };
  } finally {
    locale.set(getLocale());
    createApp(initialProps);
  }
};

init();
