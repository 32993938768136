import { posthog, PostHogPluginMissingError } from '../../plugins/posthog';
import { featureFlagsStore } from '../../store/featureFlagsStore';

export class PostHogFeatureFlagsError extends Error {
  constructor() {
    super('PostHog onFeatureFlags error');
    this.name = 'PostHogFeatureFlagsError';
  }
}

export const featureFlagsNames = {
  due_overdue_amount: 'due_overdue_amount',
  payment_status: 'payment_status',
  repayment: 'repayment',
};

export function initFeatureFlags(): Promise<void> {
  return new Promise((resolve, reject) => {
    if (!posthog) {
      reject(new PostHogPluginMissingError());
      return;
    }
    if (!posthog.onFeatureFlags) {
      reject(new PostHogFeatureFlagsError());
      return;
    }

    posthog.onFeatureFlags(() => {
      const flags = {
        due_overdue_amount:
          posthog.isFeatureEnabled(featureFlagsNames.due_overdue_amount) ??
          false,
        payment_status:
          posthog.isFeatureEnabled(featureFlagsNames.payment_status) ?? false,
        repayment:
          posthog.isFeatureEnabled(featureFlagsNames.repayment) ?? false,
      };
      featureFlagsStore.setFeatureFlags(flags);

      resolve();
    });
  });
}
