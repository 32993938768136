import { writable } from 'svelte/store';

const createTopBarStore = () => {
  const initialState = {
    button: {
      label: '',
      onClick: () => {},
    },
    context: '',
    showBackButton: false,
    title: 'MPay',
  };
  const { set, subscribe, update } = writable(initialState);

  return {
    reset: () => set(initialState),
    setButton: (value: { label: string; onClick: () => void }) =>
      update((state) => ({ ...state, button: value })),
    setContext: (value: string) =>
      update((state) => ({ ...state, context: value })),
    setShowBackButton: (value: boolean) =>
      update((state) => ({ ...state, showBackButton: value })),
    setTitle: (value: string) =>
      update((state) => ({ ...state, title: value })),
    subscribe,
  };
};

export const topBar = createTopBarStore();
