<script lang="ts">
  import { t } from 'svelte-i18n';
  import { settingsStore } from '../../store/settingsStore';
  import { getRippleEffect } from '../utils/animations';
  import { isAndroidWebView } from '../utils/detectPlatform';
  import { EventsMapKeys, trackEvents } from '../utils/tracking';

  export let navigationTabs = [
    { label: $t('tabs.invoices'), value: 1 },
    { label: $t('tabs.mcredit'), value: 2 },
  ];

  const trackClick = (currentTab: number, toTab: number) => {
    trackEvents(EventsMapKeys.TAB_CLICK, { currentTab, toTab });
  };

  const handleClick = (tabValue: number) => {
    trackClick($settingsStore.activeTab, tabValue);

    settingsStore.setActiveTab(tabValue);

    window.history.pushState({}, '', tabValue === 1 ? '/' : '/mcredit');
    window.scrollTo(0, 0);

    // reset the summary graph after the animation is done
    setTimeout(() => settingsStore.setIsGraphExpanded(false), 500);
  };

  const createRipple = (event: MouseEvent) => {
    const { circle } = getRippleEffect(event);
    circle.classList.add('bg-primary-tint80');
  };
</script>

<div
  data-cy="navigation-tabs"
  class="fixed z-50 flex h-navBar w-screen bg-primary-base text-white {!isAndroidWebView()
    ? 'top-topBar'
    : 'top-0'}"
>
  {#each navigationTabs as tab}
    <button
      class={[
        'relative w-1/2 overflow-hidden px-4 pt-[14px] text-center text-android-label-large text-sm ios:text-ios-callout ios:font-semibold',
        $settingsStore.activeTab === tab.value
          ? 'border-b-[3px] border-b-white pb-[12px]'
          : 'border-b-[1px] border-b-primary-tint20 pb-[14px]',
      ].join(' ')}
      data-cy={'nav-tab-' + tab.value}
      on:click={() => handleClick(tab.value)}
      on:mousedown={createRipple}
    >
      {tab.label}
    </button>
  {/each}
</div>
