<script lang="ts">
  import { t } from 'svelte-i18n';
  import { fade } from 'svelte/transition';
  import { settingsStore } from '../../store/settingsStore';
  import { getCurrencyUnit } from '../utils/helpers';

  export let h2Classes: string = '';
  export let month: string = '-';
  export let year: number | string = '-';
  export let gross: string = '-';
  export let net: string = '-';
  export let onGraphClick: () => void;
</script>

<div
  class="mr-2 flex h-full w-[calc(100%-14rem)] flex-shrink-0 flex-grow-0 flex-col justify-center pr-4"
  on:click={onGraphClick}
  on:keydown={onGraphClick}
  role="button"
  tabindex="0"
  data-cy="bar-graph-total-summary"
>
  <h1
    class="mb-1 whitespace-nowrap text-android-label-small text-primary-tint40 ios:text-ios-caption ios:font-semibold"
  >
    {$t('graphs.total_spend')}
    {#if !$settingsStore.isGraphExpanded}
      <span transition:fade={{ duration: 300 }}>
        &nbsp;{$t('graphs.in')}
        {month}
        {year}
      </span>
    {/if}
  </h1>

  <div class="flex items-baseline">
    <h2
      class={[
        'm-block-0 mb-1 whitespace-nowrap text-white transition-all duration-300',
        h2Classes,
      ].join(' ')}
    >
      {gross}
    </h2>

    {#if gross.length < 11}
      <span
        class={[
          'whitespace-nowrap text-white transition-all duration-300',
          $settingsStore.isGraphExpanded
            ? 'text-android-label-small font-medium ios:text-ios-caption ios:font-normal'
            : 'text-android-headline-small ios:text-ios-title1',
        ].join(' ')}
      >
        &nbsp;{getCurrencyUnit()}
      </span>
    {/if}
  </div>

  <h3
    class="mt-1 whitespace-nowrap text-android-label-small font-medium text-white ios:text-ios-caption ios:font-semibold"
  >
    {net}
    {getCurrencyUnit()}
    {$t('invoices.net')}
  </h3>
</div>
