import type { Env } from './types/env.types';
import localEnv from './env.local';
import ppEnv from './env.pp';
import prodEnv from './env.prod';

let envVariables: Env;
const hostnameForConfig = window?.location?.hostname;

if (/^localhost/.test(hostnameForConfig)) {
  envVariables = localEnv;
} else if (
  /^companion\.pp\.metro-fs\.com|^mfs-companion-next\.web\.app/.test(
    hostnameForConfig
  )
) {
  envVariables = ppEnv;
} else {
  envVariables = prodEnv;
}

export default envVariables;

export const {
  IDAM_AUTH,
  MPAY_API_BASE_URI,
  MPAY_ENV,
  MPAY_POSTHOG_API_HOST,
  MPAY_POSTHOG_TOKEN,
  MPAY_SENTRY_DSN,
} = envVariables;
