<script lang="ts">
  import { t } from 'svelte-i18n';
  import { invoicesStore } from '../../store/invoicesStore';
  import { DateRange } from '../constants';
  import { EventsMapKeys, trackEvents } from '../utils/tracking';
  import BottomSheet from './BottomSheet.svelte';
  import Button from './Button.svelte';
  import RadioButton from './RadioButton.svelte';

  const dateRanges = [
    DateRange.THIS_MONTH,
    DateRange.LAST_MONTH,
    DateRange.THIS_QUARTER,
    DateRange.THIS_YEAR,
    DateRange.LAST_YEAR,
  ];

  export let isOpen: boolean;
  export let setIsOpen: () => void;
  export let onSelect: (value: DateRange) => void;

  let selectedValue: DateRange = $invoicesStore.filters.dateRange;

  const trackSelection = () => {
    trackEvents(EventsMapKeys.INVOICE_DATE_PICKER, selectedValue);
  };

  const onSelectOption = () => {
    trackSelection();
    invoicesStore.setDateRangeFilter(selectedValue);
    onSelect(selectedValue);
  };

  const resetSelection = () => {
    selectedValue = DateRange.THIS_AND_LAST_YEAR;
    onSelectOption();
  };
</script>

<BottomSheet
  {isOpen}
  {setIsOpen}
  title={$t('bottom_sheet_date_range.headline')}
>
  <ul data-cy="date-range-list">
    {#each dateRanges as dateRange}
      <li
        class="flex items-center border-b border-b-primary-tint90 py-4 text-android-title-medium last:border-0 ios:text-ios-headline ios:font-semibold"
      >
        <RadioButton
          bind:group={selectedValue}
          item={dateRange}
          label={$t('bottom_sheet_date_range.' + dateRange)}
          isSelected={selectedValue === dateRange}
        />
      </li>
    {/each}
  </ul>

  <div
    class="flex w-full justify-between gap-3 border-t border-t-primary-tint90 pt-4"
  >
    <Button
      type="outlined"
      onClick={resetSelection}
      label={$t('general.cta.reset')}
    />
    <Button
      onClick={onSelectOption}
      label={$t('general.cta.save')}
      id="save-daterange-filter"
    />
  </div>
</BottomSheet>
