import { writable } from 'svelte/store';
import { featureFlagsNames } from '../lib/utils/featureFlags';

type FeatureFlags = {
  [key in keyof typeof featureFlagsNames]: boolean;
};

const createFeatureFlagsStore = () => {
  const initialState: FeatureFlags = {
    due_overdue_amount: false,
    payment_status: false,
    repayment: false,
  };

  const { set, subscribe } = writable(initialState);

  return {
    setFeatureFlags: (flags: FeatureFlags) => set(flags),
    subscribe,
  };
};

export const featureFlagsStore = createFeatureFlagsStore();
