<script lang="ts">
  import { t } from 'svelte-i18n';
  import type { BankInterface, BankName } from '../constants';
  import Icon from './Icon.svelte';

  export let name: BankName;
  export let logo: string;
  export let bankInterface: BankInterface | undefined = undefined;
  export let onClick: (
    name: BankName,
    bankInterface?: BankInterface | undefined
  ) => void;
</script>

<li
  class="mx-5 border-b border-primary-tint90 last:border-0 active:bg-primary-tint90"
>
  <button
    on:click={() => onClick(name, bankInterface)}
    class="flex w-full items-center justify-between gap-3 pb-[15px] pt-[17px]"
  >
    <div
      class="flex h-[40px] w-[40px] items-center justify-center rounded-lg bg-semantic-inverted p-2 text-semantic-neutral shadow-small"
    >
      <img src={logo} alt="logo" height="30" width="30" />
    </div>

    <div class="w-full">
      <p
        class="truncate text-left text-android-title-medium font-medium text-primary-base ios:text-ios-headline ios:font-semibold"
      >
        {bankInterface
          ? $t(`bank_selection_type.${bankInterface}`)
          : $t(`banks.${name}`)}
      </p>

      {#if bankInterface}
        <p
          class="truncate text-left text-android-body-small text-primary-tint40 ios:text-ios-footnote"
        >
          {$t(`banks.${name}`)}
        </p>
      {/if}
    </div>

    <div class="flex items-center justify-center text-semantic-muted">
      <Icon iconName="ChevronRight" />
    </div>
  </button>
</li>
