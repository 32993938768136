// a list of possible actions, not all of them are used but those are the recommended ones
export enum Action {
  Add = 'add',
  Cancel = 'cancel',
  Click = 'click',
  Close = 'close',
  Delete = 'delete',
  Fail = 'fail',
  Invite = 'invite',
  LoadMore = 'load_more',
  Send = 'send',
  Submit = 'submit',
  Update = 'update',
  View = 'view',
}

// as we don't have a route for every "page" let's have them as contexts
export enum Context {
  BankSelection = 'bank-selection',
  BankSelectionType = 'bank-selection-type',
  Home = 'home',
  Invoice = 'invoice',
  InvoiceDetails = 'invoice-details',
  Invoices = 'invoices',
  Mcredit = 'mCredit',
  Repayment = 'repayment',
}

export enum Target {
  BackToInvoiceButton = 'back_to_invoice_button',
  BackToOverview = 'back_to_overview',
  BankListItem = 'bank_list_item',
  InvoiceDatePicker = 'invoice_date_picker',
  InvoiceDatePickerRange = 'invoice_date_picker_range',
  InvoiceItemLink = 'invoice_item_link',
  InvoiceListItem = 'invoice_list_item',
  InvoicesOverview = 'overview',
  InvoicesTab = 'invoices_tab',
  MCreditTab = 'mcredit_tab',
  MyInvoices = 'my_invoices_filter',
  NavigateBack = 'back_arrow',
  OverviewGraph = 'overview_graph',
  OverviewGraphSingleMonth = 'overview_graph_single_month',
  PaymentDetails = 'payment_details',
  PaymentMethodFilter = 'payment_method_filter',
  PdfNextPage = 'pdf_next_page',
  RepayAmount = 'repay_open_amount',
  RepaymentContinue = 'continue',
  SelectedBank = 'selected_bank',
  TotalOverviewSum = 'total_overview_summary',
  WebView = 'webview',
}
