<script lang="ts">
  import { onMount } from 'svelte';
  import { t } from 'svelte-i18n';
  import { navigate } from 'svelte-routing';
  import { MPAY_API_BASE_URI } from '../../env';
  import { routes } from '../../routes';
  import { settingsStore } from '../../store/settingsStore';
  import Icon from '../components/Icon.svelte';
  import { BankInterface, type BankName, MPayApi } from '../constants';
  import { Context, Target } from '../utils/analytics/keys';
  import { getBank } from '../utils/banks';
  import { sendRequest } from '../utils/fetch';
  import { EventsMapKeys, trackEvents } from '../utils/tracking';

  let isLoadingSelectedBank = true;

  function onSelectedBankClick() {
    trackEvents(EventsMapKeys.CTA_CLICK, {
      category: Context.Repayment,
      target: Target.SelectedBank,
    });
    navigate(routes.repaymentBankSelection.path);
  }

  async function fetchSelectedBankAccount() {
    isLoadingSelectedBank = true;
    try {
      const url = `${MPAY_API_BASE_URI}/${MPayApi.PROFILES}/${$settingsStore.credentials.accountId}`;
      const bank = await sendRequest<{
        bankInterface: BankInterface;
        selectedBank: BankName;
      }>(url);

      settingsStore.setSelectedBankAccount(bank);
    } catch (error) {
      console.warn(error);
    } finally {
      isLoadingSelectedBank = false;
    }
  }

  onMount(() => {
    fetchSelectedBankAccount();
  });
</script>

{#if isLoadingSelectedBank || $settingsStore.selectedBank}
  <section class="mx-5 mt-6 grid gap-3">
    <p
      class="py-2 text-android-label-small font-medium text-primary-tint40 ios:text-ios-caption ios:font-semibold"
    >
      {$t('repayment.selected_bank')}
    </p>

    <button
      disabled={isLoadingSelectedBank}
      on:click={onSelectedBankClick}
      class="flex items-center justify-between gap-3 rounded-xl bg-semantic-inverted p-4 text-primary-base"
    >
      {#if isLoadingSelectedBank}
        <div class="mx-auto flex h-[40px] items-center">
          <Icon iconName="Spinner" />
        </div>
      {:else if $settingsStore.selectedBank}
        <div
          class="flex h-[40px] min-w-[40px] items-center justify-center rounded-lg bg-semantic-inverted p-2 shadow-small"
        >
          <img
            src={getBank($settingsStore.selectedBank.name)?.logo}
            alt="logo"
            height="30"
            width="30"
          />
        </div>
        <div class="w-full">
          <p
            class="flex w-full truncate text-android-title-medium font-medium ios:text-ios-headline ios:font-semibold"
          >
            {$t(`banks.${$settingsStore.selectedBank.name}`)}
          </p>

          {#if $settingsStore.selectedBank.bankInterface}
            <p
              class="truncate text-left text-android-body-small text-primary-tint40 ios:text-ios-footnote"
            >
              {$t(
                `bank_selection_type.${$settingsStore.selectedBank.bankInterface}`
              )}
            </p>
          {/if}
        </div>

        <div class="flex items-center justify-center">
          <Icon iconName="Edit" />
        </div>
      {/if}
    </button>
  </section>
{/if}
