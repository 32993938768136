<script lang="ts">
  import { getCurrencyUnit } from '../utils/helpers';

  export let titleLeft: string;
  export let titleRight: string;
  export let valueLeft: string | undefined = undefined;
  export let valueRight: string | undefined = undefined;
  export let isTextRed = false;
  export let showCurrency = false;

  $: leftValue = valueLeft ?? '--';
  $: rightValue = valueRight ?? '--';
  $: currencyUnit = showCurrency ? ' ' + getCurrencyUnit() : '';
</script>

<div class="flex">
  <div class="flex grow basis-0 flex-col gap-1 py-2">
    <div
      class="px-1 text-center text-android-body-small text-primary-tint95 ios:text-ios-footnote"
    >
      {titleLeft}
    </div>
    <div
      class="px-1 text-center text-android-title-medium text-primary-tint95 ios:text-ios-headline ios:font-semibold"
    >
      {leftValue}{currencyUnit}
    </div>
  </div>
  <div
    class="flex grow basis-0 flex-col gap-1 border-l py-2 text-primary-tint20"
  >
    <div
      class="px-1 text-center text-android-body-small text-primary-tint95 ios:text-ios-footnote"
    >
      {titleRight}
    </div>
    <div
      class="px-1 text-center text-android-title-medium ios:text-ios-headline ios:font-semibold {isTextRed
        ? 'text-red-base'
        : 'text-primary-tint95'}"
    >
      {rightValue}{currencyUnit}
    </div>
  </div>
</div>
