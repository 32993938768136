import {
  getLocaleFromNavigator,
  getLocaleFromQueryString,
  init,
  register,
} from 'svelte-i18n';
import { get } from 'svelte/store';
import { settingsStore } from './store/settingsStore';

export const locales = {
  en: {
    dateLocale: 'en-US',
    translationKey: 'en',
  },
  es: {
    dateLocale: 'es-ES',
    translationKey: 'es',
  },
  it: {
    dateLocale: 'it-IT',
    translationKey: 'it',
  },
  ro: {
    dateLocale: 'ro-RO',
    translationKey: 'ro',
  },
};

type LocaleKey = keyof typeof locales;

export const getLocale = (): string => {
  const queryLocale = getLocaleFromQueryString('lang');
  const navLocale = getLocaleFromNavigator();

  if (queryLocale !== null && queryLocale !== undefined) {
    if (queryLocale in locales) {
      return locales[queryLocale as LocaleKey].dateLocale;
    }
  }

  if (
    navLocale &&
    Object.values(locales).find((locale) => navLocale === locale.dateLocale)
  ) {
    return navLocale;
  }

  // for the case if and when navigator is overwritten manually with 'xx' format instead of browser's 'xx-XX'
  if (navLocale && navLocale in locales) {
    return locales[navLocale as LocaleKey].dateLocale;
  }

  return locales.en.dateLocale;
};

export const getLocaleFromCountryCode = () => {
  const settings = get(settingsStore);
  const countryCode = settings.accountDetails.country;

  if (countryCode && countryCode in locales) {
    return locales[countryCode as LocaleKey].dateLocale;
  }

  // fallback to browser's locale
  const navLocale = getLocaleFromNavigator();
  return navLocale || locales.en.dateLocale;
};

register(locales.en.translationKey, () => import('./lib/i18n/en.json'));
register(locales.es.translationKey, () => import('./lib/i18n/es.json'));
register(locales.it.translationKey, () => import('./lib/i18n/it.json'));
register(locales.ro.translationKey, () => import('./lib/i18n/ro.json'));

init({
  fallbackLocale: locales.en.translationKey,
  initialLocale: getLocale(),
});
