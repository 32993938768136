<script lang="ts">
  import { featureFlagsStore } from '../../store/featureFlagsStore';
  import { settingsStore } from '../../store/settingsStore';
  import { isAndroidWebView } from '../utils/detectPlatform';

  $: firstTabClasses = (() => {
    const baseClasses = ['fixed z-20 mt-auto h-36 w-screen'];
    const topClass = isAndroidWebView() ? 'top-0' : 'top-topBar';
    const topClassWithAccess = isAndroidWebView()
      ? 'top-navBar'
      : 'top-topAndNavBars';

    return [
      ...baseClasses,
      $settingsStore.accountDetails.mcreditAccess
        ? topClassWithAccess
        : topClass,
    ];
  })();

  $: secondTabClasses = (() => {
    const baseClasses = ['mt-navBar'];
    const getHeightClass = () => {
      // if only overdue is visible
      if (
        $featureFlagsStore.due_overdue_amount &&
        !$featureFlagsStore.repayment
      )
        return ['h-[480px]'];
      // if only repayment is visible
      if (
        !$featureFlagsStore.due_overdue_amount &&
        $featureFlagsStore.repayment
      )
        return ['h-[455px]'];
      // if both are visible
      if ($featureFlagsStore.due_overdue_amount && $featureFlagsStore.repayment)
        return ['h-[535px]'];

      // if none are visible, default
      return ['h-[400px]'];
    };

    return [...baseClasses, ...getHeightClass()];
  })();

  $: activeTabClasses = (() => {
    const paddingClass = $settingsStore.isGraphExpanded ? 'py-7' : 'py-9';

    return [
      paddingClass,
      ...($settingsStore.activeTab === 1 ? firstTabClasses : secondTabClasses),
    ];
  })();
</script>

<div
  class={[
    'bg-primary-darken20 px-6 transition-all duration-300',
    ...activeTabClasses,
  ].join(' ')}
  data-cy="bar-graph-section"
>
  <slot />
</div>
