<script lang="ts">
  export let color: string;
  export let size: string;
</script>

<svg
  width={size}
  height={size}
  viewBox="0 0 24 24"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M12 0C18.6176 0 24 5.38279 24 11.9993C24 18.6172 18.6176 24 12 24C5.38247 24 0 18.6172 0 11.9993C0 5.38279 5.38247 0 12 0ZM12 2.18222C6.58553 2.18222 2.18208 6.58593 2.18208 11.9993C2.18208 17.4141 6.58553 21.8178 12 21.8178C17.4131 21.8178 21.818 17.4141 21.818 11.9993C21.818 6.58593 17.4131 2.18222 12 2.18222ZM12.7561 9.75C13.3584 9.75 13.8471 10.2388 13.8471 10.8411V16.8248H14.4552C15.0575 16.8248 15.5463 17.3136 15.5463 17.9159C15.5463 18.5181 15.0575 19.007 14.4552 19.007H10.091C9.48879 19.007 9 18.5181 9 17.9159C9 17.3136 9.48879 16.8248 10.091 16.8248H11.665V11.9322H10.878C10.2758 11.9322 9.787 11.4434 9.787 10.8411C9.787 10.2388 10.2758 9.75 10.878 9.75H12.7561ZM11.9971 5.25C12.8714 5.25 13.5842 5.96141 13.5842 6.8372C13.5842 7.713 12.8714 8.42439 11.9971 8.42439C11.1214 8.42439 10.41 7.713 10.41 6.8372C10.41 5.96141 11.1214 5.25 11.9971 5.25Z"
    fill={color}
  />
</svg>
