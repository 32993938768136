<script lang="ts">
  import { onDestroy } from 'svelte';
  import { t } from 'svelte-i18n';
  import { invoicesStore } from '../../store/invoicesStore';
  import { bodyScrollLock } from '../../store/UiStore';
  import { DateRange } from '../constants';
  import { Context, Target } from '../utils/analytics/keys';
  import { EventsMapKeys, trackEvents } from '../utils/tracking';
  import Chip from './Chip.svelte';
  import DateRangeSheet from './DateRangeSheet.svelte';

  let isVisible = false;
  export let onSelect: (...args: unknown[]) => unknown;

  $: isRangeSelected =
    $invoicesStore.filters.dateRange !== DateRange.THIS_AND_LAST_YEAR;

  $: bodyScrollLock.set(isVisible);

  onDestroy(() => bodyScrollLock.set(false));

  const toggleVisibility = () => {
    isVisible = !isVisible;
  };

  const onClick = () => {
    trackEvents(EventsMapKeys.CTA_CLICK, {
      category: Context.Invoices,
      target: Target.InvoiceDatePicker,
    });

    toggleVisibility();
  };

  const onRangeSelect = () => {
    toggleVisibility();
    onSelect();
  };
</script>

<Chip
  id="invoice-date-button"
  on:click={onClick}
  withChevron
  isActive={isRangeSelected}
>
  <p class="truncate">
    {$t('invoices.invoice_date')}
  </p>
</Chip>

<DateRangeSheet
  isOpen={isVisible}
  setIsOpen={toggleVisibility}
  onSelect={onRangeSelect}
/>
