<script lang="ts">
  export let color: string;
</script>

<svg
  width="125"
  height="80"
  viewBox="0 0 125 80"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <g clip-path="url(#clip0_12841_15654)">
    <path
      d="M112.244 56.554C111.14 51.5885 114.083 47.1287 115.692 45.5195C113.853 45.5195 110.037 46.3471 109.485 49.6575C108.933 52.9678 111.094 55.6345 112.244 56.554Z"
      fill="#002D72"
    />
    <path
      d="M112.244 56.554C111.14 51.5885 114.083 47.1287 115.692 45.5195C113.853 45.5195 110.037 46.3471 109.485 49.6575C108.933 52.9678 111.094 55.6345 112.244 56.554Z"
      fill="white"
      fill-opacity="0.95"
    />
    <circle
      cx="114.313"
      cy="51.0364"
      r="5.51724"
      stroke="#002D72"
      stroke-width="2.75862"
    />
    <circle
      cx="114.313"
      cy="51.0364"
      r="5.51724"
      stroke="white"
      stroke-opacity="0.6"
      stroke-width="2.75862"
    />
    <path
      d="M7.32727 78.625H123.189"
      stroke="#002D72"
      stroke-width="2.75862"
      stroke-linecap="round"
    />
    <path
      d="M7.32727 78.625H123.189"
      stroke="white"
      stroke-opacity="0.95"
      stroke-width="2.75862"
      stroke-linecap="round"
    />
    <path
      d="M16.9825 78.625L113.534 78.625"
      stroke="#002D72"
      stroke-width="2.75862"
      stroke-linecap="round"
    />
    <path
      d="M16.9825 78.625L113.534 78.625"
      stroke="white"
      stroke-opacity="0.6"
      stroke-width="2.75862"
      stroke-linecap="round"
    />
    <path
      d="M10.0861 78.625H10.7757"
      stroke="#002D72"
      stroke-width="2.75862"
      stroke-linecap="round"
    />
    <path
      d="M10.0861 78.625H10.7757"
      stroke="white"
      stroke-opacity="0.6"
      stroke-width="2.75862"
      stroke-linecap="round"
    />
    <path
      d="M119.052 78.625H119.742"
      stroke="#002D72"
      stroke-width="2.75862"
      stroke-linecap="round"
    />
    <path
      d="M119.052 78.625H119.742"
      stroke="white"
      stroke-opacity="0.6"
      stroke-width="2.75862"
      stroke-linecap="round"
    />
    <path
      d="M108.805 19.4441C107.965 15.6618 110.206 12.2648 111.432 11.0391C110.031 11.0391 107.125 11.6694 106.704 14.191C106.284 16.7125 107.93 18.7437 108.805 19.4441Z"
      fill="#002D72"
    />
    <path
      d="M108.805 19.4441C107.965 15.6618 110.206 12.2648 111.432 11.0391C110.031 11.0391 107.125 11.6694 106.704 14.191C106.284 16.7125 107.93 18.7437 108.805 19.4441Z"
      fill="white"
      fill-opacity="0.95"
    />
    <circle cx="110.382" cy="15.2417" r="4.5312" fill="#002D72" />
    <circle
      cx="110.382"
      cy="15.2417"
      r="4.5312"
      fill="white"
      fill-opacity="0.95"
    />
    <circle
      cx="110.382"
      cy="15.2417"
      r="4.5312"
      stroke="#002D72"
      stroke-width="2.75862"
    />
    <circle
      cx="110.382"
      cy="15.2417"
      r="4.5312"
      stroke="white"
      stroke-opacity="0.6"
      stroke-width="2.75862"
    />
    <path
      d="M5.35817 40.1277C4.51767 36.3454 6.75901 32.9484 7.98475 31.7227C6.58395 31.7227 3.67728 32.353 3.25703 34.8745C2.83678 37.3961 4.48269 39.4273 5.35817 40.1277Z"
      fill="#002D72"
    />
    <path
      d="M5.35817 40.1277C4.51767 36.3454 6.75901 32.9484 7.98475 31.7227C6.58395 31.7227 3.67728 32.353 3.25703 34.8745C2.83678 37.3961 4.48269 39.4273 5.35817 40.1277Z"
      fill="white"
      fill-opacity="0.95"
    />
    <circle cx="6.93424" cy="35.9253" r="4.5312" fill="#002D72" />
    <circle
      cx="6.93424"
      cy="35.9253"
      r="4.5312"
      fill="white"
      fill-opacity="0.95"
    />
    <circle
      cx="6.93424"
      cy="35.9253"
      r="4.5312"
      stroke="#002D72"
      stroke-width="2.75862"
    />
    <circle
      cx="6.93424"
      cy="35.9253"
      r="4.5312"
      stroke="white"
      stroke-opacity="0.6"
      stroke-width="2.75862"
    />
    <path
      d="M16.9048 61.5899C16.4005 59.3205 17.7453 57.2823 18.4807 56.5469C17.6403 56.5469 15.8963 56.9251 15.6441 58.438C15.392 59.9509 16.3795 61.1696 16.9048 61.5899Z"
      fill="#002D72"
    />
    <path
      d="M16.9048 61.5899C16.4005 59.3205 17.7453 57.2823 18.4807 56.5469C17.6403 56.5469 15.8963 56.9251 15.6441 58.438C15.392 59.9509 16.3795 61.1696 16.9048 61.5899Z"
      fill="white"
      fill-opacity="0.95"
    />
    <circle cx="17.8502" cy="59.0669" r="3.27044" fill="#002D72" />
    <circle
      cx="17.8502"
      cy="59.0669"
      r="3.27044"
      fill="white"
      fill-opacity="0.95"
    />
    <circle
      cx="17.8502"
      cy="59.0669"
      r="3.27044"
      stroke="#002D72"
      stroke-width="2.75862"
    />
    <circle
      cx="17.8502"
      cy="59.0669"
      r="3.27044"
      stroke="white"
      stroke-opacity="0.6"
      stroke-width="2.75862"
    />
    <path
      d="M62.4989 5.23761L69.4816 2.32812L74.7187 7.68157H82.2834L85.1928 14.548L92.0592 17.4574V25.0221L97.4127 30.2592L94.5032 37.2419L97.4127 44.2247L92.0592 49.4617V57.0264L85.1928 59.9359L82.2834 66.8023H74.7187L69.4816 72.1557L62.4989 69.2462L55.5161 72.1557L50.279 66.8023H42.7144L39.8049 59.9359L32.9385 57.0264V49.4617L27.5851 44.2247L30.4946 37.2419L27.5851 30.2592L32.9385 25.0221V17.4574L39.8049 14.548L42.7144 7.68157H50.279L55.5161 2.32812L62.4989 5.23761Z"
      fill="white"
    />
    <path
      d="M67.154 65.7563L60.1713 68.6658L54.9342 63.3124H47.3696L44.4601 56.446L37.5937 53.5365V45.9718L32.2403 40.7348L35.1497 33.752L32.2403 26.7693L37.5937 21.5322V15.4805L32.9385 17.4589V25.0236L27.5851 30.2606L30.4946 37.2434L27.5851 44.2262L32.9385 49.4632V57.0279L39.8049 59.9374L42.7144 66.8037H50.279L55.5161 72.1572L62.4989 69.2477L69.4816 72.1572L73.3222 68.3167L67.154 65.7563Z"
      fill="#002D72"
    />
    <path
      d="M67.154 65.7563L60.1713 68.6658L54.9342 63.3124H47.3696L44.4601 56.446L37.5937 53.5365V45.9718L32.2403 40.7348L35.1497 33.752L32.2403 26.7693L37.5937 21.5322V15.4805L32.9385 17.4589V25.0236L27.5851 30.2606L30.4946 37.2434L27.5851 44.2262L32.9385 49.4632V57.0279L39.8049 59.9374L42.7144 66.8037H50.279L55.5161 72.1572L62.4989 69.2477L69.4816 72.1572L73.3222 68.3167L67.154 65.7563Z"
      fill="white"
      fill-opacity="0.95"
    />
    <g opacity="0.2">
      <path
        d="M97.4124 30.2592L92.0589 25.0221V17.4574L85.1925 14.548L82.2831 7.68157H74.7184L69.4813 2.32812L62.4986 5.23761L55.5158 2.32812L51.6753 6.16864L57.8434 8.72899L64.8262 5.8195L70.0632 11.173H77.6279L80.5374 18.0393L87.4037 20.9488V28.5135L92.7572 33.7505L89.8477 40.7333L92.7572 47.7161L87.4037 52.9531V58.8885L92.0589 57.0264V49.4617L97.4124 44.2247L94.5029 37.2419L97.4124 30.2592Z"
        fill="white"
      />
    </g>
    <path
      d="M62.4989 5.23761L69.4816 2.32812L74.7187 7.68157H82.2834L85.1928 14.548L92.0592 17.4574V25.0221L97.4127 30.2592L94.5032 37.2419L97.4127 44.2247L92.0592 49.4617V57.0264L85.1928 59.9359L82.2834 66.8023H74.7187L69.4816 72.1557L62.4989 69.2462L55.5161 72.1557L50.279 66.8023H42.7144L39.8049 59.9359L32.9385 57.0264V49.4617L27.5851 44.2247L30.4946 37.2419L27.5851 30.2592L32.9385 25.0221V17.4574L39.8049 14.548L42.7144 7.68157H50.279L55.5161 2.32812L62.4989 5.23761Z"
      stroke={color}
      stroke-width="2.75862"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M62.4989 5.23761L69.4816 2.32812L74.7187 7.68157H82.2834L85.1928 14.548L92.0592 17.4574V25.0221L97.4127 30.2592L94.5032 37.2419L97.4127 44.2247L92.0592 49.4617V57.0264L85.1928 59.9359L82.2834 66.8023H74.7187L69.4816 72.1557L62.4989 69.2462L55.5161 72.1557L50.279 66.8023H42.7144L39.8049 59.9359L32.9385 57.0264V49.4617L27.5851 44.2247L30.4946 37.2419L27.5851 30.2592L32.9385 25.0221V17.4574L39.8049 14.548L42.7144 7.68157H50.279L55.5161 2.32812L62.4989 5.23761Z"
      stroke="white"
      stroke-opacity="0.2"
      stroke-width="2.75862"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M47.7306 41.0904L58.1091 49.7391C58.7389 50.264 59.6849 50.1268 60.1397 49.4446L77.2669 23.7539"
      stroke={color}
      stroke-width="2.75862"
      stroke-linecap="round"
    />
    <path
      d="M47.7306 41.0904L58.1091 49.7391C58.7389 50.264 59.6849 50.1268 60.1397 49.4446L77.2669 23.7539"
      stroke="white"
      stroke-opacity="0.2"
      stroke-width="2.75862"
      stroke-linecap="round"
    />
  </g>
  <defs>
    <clipPath id="clip0_12841_15654">
      <rect
        width="124.138"
        height="80"
        fill="white"
        transform="translate(0.43103)"
      />
    </clipPath>
  </defs>
</svg>
