import type { Invoice, MonthlyInvoices } from '../../types/mpay.types';
import { getMonthYearFromDateTranslation } from './translations';

export function getInvoiceById(
  invoices: MonthlyInvoices,
  id: string
): Invoice | null {
  let foundInvoice = null;

  Object.keys(invoices).forEach((month) => {
    const currResult = invoices[month].find((invoice) => invoice.number === id);

    if (currResult) {
      foundInvoice = currResult;
    }
  });

  return foundInvoice;
}

export function getMergedInvoices(
  oldVal: MonthlyInvoices,
  newVal: MonthlyInvoices
): MonthlyInvoices {
  if (Object.keys(oldVal).length > 0) {
    Object.keys(newVal).forEach((key) => {
      if (oldVal[key]) {
        const existingInvoices = new Set(
          oldVal[key].map((invoice) => invoice.number)
        );

        // filter out invoices with duplicate numbers
        const uniqueNewInvoices = newVal[key].filter(
          (invoice) => !existingInvoices.has(invoice.number)
        );

        oldVal[key].push(...uniqueNewInvoices);
      } else {
        oldVal[key] = newVal[key];
      }
    });
    return oldVal;
  } else {
    return newVal;
  }
}

export function getSortedInvoicesByMonthYear(
  invoices: Invoice[]
): MonthlyInvoices {
  return invoices.reduce((acc: MonthlyInvoices, curr) => {
    const invMonthYear = getMonthYearFromDateTranslation(curr.date);

    acc[invMonthYear] = [...(acc[invMonthYear] || []), curr];

    return acc;
  }, {});
}
