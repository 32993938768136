<script lang="ts">
  import { onMount } from 'svelte';
  import { t } from 'svelte-i18n';
  import { MPAY_API_BASE_URI } from '../../env';
  import { hideLoader, showLoader } from '../../lib/JSBridge/loaderController';
  import { posthog } from '../../plugins/posthog';
  import { featureFlagsStore } from '../../store/featureFlagsStore';
  import { settingsStore } from '../../store/settingsStore';
  import { topBar } from '../../store/TopBarStore';
  import BankListItem from '../components/BankListItem.svelte';
  import Icon from '../components/Icon.svelte';
  import { BankInterface, type BankName, MPayApi } from '../constants';
  import { navigateBack } from '../JSBridge/navigationController';
  import Layout from '../layouts/main.svelte';
  import { Context } from '../utils/analytics/keys';
  import { type BankRecord, getBank, isBankValid } from '../utils/banks';
  import { sendRequest } from '../utils/fetch';
  import { EventsMapKeys, trackEvents } from '../utils/tracking';

  export let bank: string;
  let selectedBank: BankRecord | undefined = undefined;

  const onSelect = async (
    bankName: BankName,
    bankInterface?: BankInterface
  ) => {
    showLoader();
    trackEvents(EventsMapKeys.BANK_SELECT, { bank, bankInterface });

    try {
      await sendRequest<{
        bankInterface?: BankInterface;
        selectedBank: BankName;
      }>(
        `${MPAY_API_BASE_URI}/${MPayApi.PROFILES}/${$settingsStore.credentials.accountId}`,
        {
          body: JSON.stringify({
            bankInterface,
            selectedBank: bankName,
          }),
          method: 'POST',
        }
      );
    } catch (error) {
      console.error(error);
    } finally {
      hideLoader();
    }

    window.history.go(-2); // to get back to the parent route of repayment
  };

  onMount(() => {
    if (!$featureFlagsStore.repayment || !bank || !isBankValid(bank)) {
      return navigateBack();
    }

    selectedBank = getBank(bank);

    posthog.capture('$pageview');

    topBar.setTitle($t('bank_selection.title'));
    topBar.setContext(Context.BankSelection);
    topBar.setShowBackButton(true);
  });
</script>

<Layout>
  <div class="px-5 pb-6 pt-8">
    <h1
      class="mb-2 text-android-headline-small font-medium text-primary-base ios:text-ios-title1 ios:font-semibold"
    >
      {$t('bank_selection_type.header')}
    </h1>

    <p class="text-android-body-large text-primary-base ios:text-ios-body">
      {$t('bank_selection_type.description')}
    </p>
  </div>

  {#if !selectedBank}
    <div class="flex w-full justify-center">
      <Icon iconName="Spinner" />
    </div>
  {:else}
    <ul>
      {#each selectedBank.bankInterfaces as bankInterface}
        <BankListItem
          name={selectedBank.name}
          logo={selectedBank.logo}
          {bankInterface}
          onClick={onSelect}
        />
      {/each}
    </ul>
  {/if}
</Layout>
