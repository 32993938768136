import { writable } from 'svelte/store';
import { bodyScrollLock } from './UiStore';

type DialogState = {
  actions: Array<() => void>;
  showDialog: boolean;
};

// retries failed API calls with a single dialog retry action
const createDialogStore = () => {
  const initialState = {
    actions: [],
    showDialog: false,
  };
  const { set, subscribe, update } = writable<DialogState>(initialState);

  return {
    reset: () => {
      bodyScrollLock.set(false);

      set(initialState);
    },
    setRetryAction: (action: () => void) => {
      bodyScrollLock.set(true);

      update((state) => ({
        ...state,
        actions: [...state.actions, action],
        showDialog: true,
      }));
    },
    subscribe,
  };
};

export const dialogStore = createDialogStore();
