import * as Sentry from '@sentry/svelte';
import { MPAY_ENV, MPAY_SENTRY_DSN } from '../env';
import { version } from './../../package.json';

export function initializeSentry() {
  Sentry.init({
    dsn: MPAY_SENTRY_DSN,
    enabled: MPAY_ENV !== 'local',
    environment: MPAY_ENV,
    integrations: [
      Sentry.replayIntegration(),
      Sentry.captureConsoleIntegration({
        levels: ['error'],
      }),
    ],
    release:
      MPAY_ENV === 'production' ? version : import.meta.env.VITE_GIT_SHA || '',
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    // Performance Monitoring
    tracesSampleRate: 1.0, // Capture 100% of the transactions
  });
}
