<script lang="ts">
  import Icon from './Icon.svelte';

  export let label: string;
  export let value: number | string;
  export let onChange: (value: string) => void;
  export let id = '';

  $: isFilled = value !== '';

  function handleInput(event: Event) {
    const target = event.target as HTMLInputElement;
    onChange(target.value);
  }

  function clearInput() {
    onChange('');
  }
</script>

<div class="relative">
  <div class="absolute pb-2 pl-4 pr-3 pt-4">
    <Icon iconName="Search" />
  </div>

  <button
    on:click={clearInput}
    class={`${!isFilled ? 'hidden' : 'absolute right-0 pb-2 pl-3 pr-4 pt-4 text-primary-tint40'}`}
  >
    <Icon iconName="CancelFill" />
  </button>

  <input
    {id}
    type="text"
    class="peer block w-full appearance-none rounded border border-primary-tint40 bg-semantic-inverted pb-2.5 pl-12 pt-4 !text-base text-android-body-large text-semantic-neutral focus:border focus:border-green-base focus:outline-none ios:pb-1.5 ios:pt-5 ios:text-ios-body"
    placeholder=" "
    {value}
    on:input={handleInput}
  />

  <label
    for={id}
    class="absolute start-12 top-4 z-10 origin-[0] -translate-y-4 scale-75 transform text-primary-tint40 duration-300 peer-placeholder-shown:translate-y-0 peer-placeholder-shown:scale-100 peer-focus:-translate-y-4 peer-focus:scale-75 ios:font-light"
  >
    {label}
  </label>
</div>
