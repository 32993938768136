<script lang="ts">
  import { t } from 'svelte-i18n';
  import type { IconName } from '../../types/icons.types';
  import { dialogStore } from '../../store/dialogStore';
  import { navigateBack } from '../JSBridge/navigationController';
  import Button from './Button.svelte';
  import Icon from './Icon.svelte';

  export let showDialog = false;
  export let title = 'error_dialog.title';
  export let description = '';
  export let cancelButtonLabel = 'back';
  export let primaryButtonLabel = 'retry';
  export let onPrimaryClick: Array<() => void>;
  export let shouldNavigateBack = true;
  export let iconName: IconName = 'DatabaseWarning';
  export let showCancelButton = true;
  export let plainIcon = false;
  export let iconColor = 'text-semantic-neutral'; // that should be a tailwind class
  export let centerContent = false;

  let dialog: HTMLDialogElement;

  $: if (dialog && showDialog) dialog.showModal();

  const _onPrimaryClick = () => {
    onPrimaryClick.forEach((action) => action());
    dialog.close();
    dialogStore.reset();
  };

  const _onCancel = () => {
    if (shouldNavigateBack) navigateBack();
    dialog.close();
    dialogStore.reset();
  };
</script>

<dialog
  data-cy="dialog"
  class="absolute w-auto rounded-xl"
  bind:this={dialog}
  on:close={() => (showDialog = false)}
>
  <div class="flex flex-col gap-8 pt-8">
    <div class="gap-6 px-6 {centerContent ? 'text-center' : ''}">
      <div
        class="mb-6 inline-flex rounded-lg {iconColor}  {plainIcon
          ? ''
          : 'bg-semantic-appBackground p-2.5'}
          "
      >
        <Icon {iconName} />
      </div>

      <div class="flex flex-col gap-4">
        <h2
          class="text-android-title-large text-primary-base ios:text-ios-title2 ios:font-semibold"
        >
          {$t(title)}
        </h2>
        {#if description}
          <p
            class="text-android-body-medium text-semantic-neutral ios:text-ios-body"
          >
            {$t(description)}
          </p>
        {/if}
      </div>
    </div>

    <div
      class="flex w-full gap-3 border-t border-t-primary-tint90 px-6 pb-6 pt-4"
    >
      {#if showCancelButton}
        <Button
          type="outlined"
          onClick={_onCancel}
          label={$t(`general.cta.${cancelButtonLabel}`)}
        />
      {/if}

      <Button
        onClick={_onPrimaryClick}
        label={$t(`general.cta.${primaryButtonLabel}`)}
      />
    </div>
  </div>
</dialog>

<style>
  dialog::backdrop {
    background: rgba(0, 0, 0, 0.4);
  }
</style>
