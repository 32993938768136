<script lang="ts">
  import { t } from 'svelte-i18n';
  import type { Payment } from '../../types/mpay.types';
  import { getCurrencyUnit } from '../utils/helpers';
  import PaymentMethodListItem from './PaymentMethodListItem.svelte';

  export let paymentList: Payment[];
</script>

<section class="mb-4 grid gap-3 ios:gap-2">
  <div
    class="mr-4 flex justify-between py-2 text-android-label-small font-medium text-primary-tint40 ios:text-ios-caption ios:font-semibold"
  >
    <p>
      {$t('invoice_details.payment_methods')}
    </p>

    <p>
      {getCurrencyUnit()}
    </p>
  </div>

  {#each paymentList as payment}
    <PaymentMethodListItem {payment} />
  {/each}
</section>
