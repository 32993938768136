<script lang="ts">
  import { t } from 'svelte-i18n';
  import type { MonthlyInvoices } from '../../types/mpay.types';
  import { topBar } from '../../store/TopBarStore';
  import { getCurrencyUnit, getNextRoute } from '../utils/helpers';
  import { EventsMapKeys, trackEvents } from '../utils/tracking';
  import ListInvoice from './ListInvoice.svelte';

  export let invoices: MonthlyInvoices;
  export let index: number;
  export let id: string;
  export let timePeriod: string;
  export let isMcredit = false;
</script>

<div
  class="flex justify-between border-b border-primary-tint90 px-5 py-2 text-android-title-small font-medium text-primary-tint40 ios:text-ios-caption ios:font-semibold"
>
  <p>{timePeriod}</p>
  {#if index === 0 && invoices[timePeriod].length}
    <p class="text-right">
      {isMcredit
        ? $t('invoices.mcredit_total') + ' ' + getCurrencyUnit()
        : getCurrencyUnit()}
    </p>
  {/if}
</div>

<ul data-cy={id}>
  {#each invoices[timePeriod] as invoice}
    <ListInvoice
      {invoice}
      href={getNextRoute(invoice)}
      onClick={() => trackEvents(EventsMapKeys.INVOICE_CLICK, $topBar.context)}
      isMcreditList={isMcredit}
    />
  {/each}
</ul>
